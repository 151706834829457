import React, { useState } from 'react';
import axios from 'axios';
import { AppBar, Toolbar, Typography, Box, Container, CssBaseline, Paper, Grid } from '@mui/material';
import MessageDisplay from './components/messageDisplay';
import MessageForm from './components/messageForm';


const App = () => {
  const [messages, setMessages] = useState([]);
  const [threadId, setThreadId] = useState(null);
  // Local testing Endpoint
  // const apiURL = 'http://localhost:3000/api/create-thread';
  // Live endpoint
  const apiURL = 'https://irserver.onrender.com/api/create-thread';
  const [isSending, setIsSending] = useState(false);

  const handleSendMessage = async (messageContent) => {
    setIsSending(true); // Disable the form when sending a message
  
    // Add user's message immediately to the messages state
    setMessages(prevMessages => [...prevMessages, { role: 'user', content: messageContent }]);
  
    try {
      const response = await axios.post(apiURL, {
        message: messageContent,
        threadId: threadId, // Include the threadId with the request
      });
  
      const { threadId: newThreadId, response: assistantResponse } = response.data;
  
      console.log(threadId);

      // Save the newThreadId if it's the first response or if it changes
      if (!threadId || threadId !== newThreadId) {
        setThreadId(newThreadId);
      }
  
      // Add the response to the messages state
      setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: assistantResponse }]);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsSending(false); // Re-enable the form
    }
  };
  
  

  return (
    <>
      <CssBaseline />
      {/* Navigation Bar */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div">
            News Release Ai
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid container component="main" sx={{ height: 'calc(100vh - 64px)' }}>
          {/* Instructions Area */}
          <Grid item 
            xs={false} // hides on extra-small screens
            sm={4} // shows on small screens and up
            md={3} // adjust this value based on your design
            sx={{
              display: { xs: 'none', sm: 'block' }, // ensure this is the only display rule
              borderRight: '1px solid rgba(0, 0, 0, 0.12)',
              height: '100%',
              overflow: 'auto' // add scrolling for smaller devices
            }}
          >
          <Box sx={{ p: 3 }}>
            <Typography variant="h5">Instructions</Typography>
            <Typography>
              <br/>
              This is an ai model trained for news release compliance. <br/>
              <br/>
              <bold>The ai is trained on:</bold> <br/>
              <br/>
              NI 43-101 <br/>
              The Canadian Securities Act <br/>
              The Securities Regulations <br/>
              The Universal Market Integrity Rules (UMIR) <br/>
              Policies and guidelines from the Investment Industry Regulatory Organization of Canada (IIROC) <br/>
              Policies and guidelines from the Mutual Fund Dealers Association (MFDA) <br/>
              Relevant policy statements or notices from the Canadian Securities Administrators (CSA) <br/>
              Among other policies. <br/>
              <br/>
              To use it just paste your news release in the text box and ask it a question. The Ai Model will analyze the news release and provide you with suggestions and improvements.

              <br/> <br/>
              In this version, if you refresh the page your chat history will be deleted and you can't access it again.
              <br/>
              <br/>
              If you have any question please contact me at daniel@orbiton.app
            </Typography>
          </Box>
        </Grid>

        {/* Message Area */}
        <Grid item xs={12} sm={8} md={9}>
          <Paper sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
              {/* Messages display */}
              <MessageDisplay messages={messages} />
            </Box>
            <Box sx={{ padding: { xs: '0', sm: '16px' }, width: '100%' }}> {/* Removing padding on xs screens */}
              {/* Make sure MessageForm is set to width: '100%' */}
              <MessageForm threadId={threadId} onSendMessage={handleSendMessage} isSending={isSending} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default App;

